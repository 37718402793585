<!--
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-06-11 09:25:34
 * @LastEditTime: 2020-08-24 13:59:17
-->
<template>
  <div>
    <div class="option-bar" v-show="!isEditStatus" v-auth="'control_panel_modify_welfare_setting'">
      <a-button type="primary" @click="handleShowEditStatus" class="mr-10" v-auth="'control_panel_modify_welfare_setting'">编辑权益</a-button>
    </div>
    <div class="equity-table">
      <a-table v-show="!isEditStatus" v-auth="'goods_manage_goods_list'" :rowKey="record => record.item_id" :columns="equityColumns" :dataSource="list" :loading="listLoading" :locale="{ emptyText: '暂无数据' }" :pagination="false">
        <template slot="level" slot-scope="text, record, index">LV{{index + 1}}</template>
        <template slot="new" slot-scope="record">
          <span v-if="record.is_new">✓</span>
          <span v-else>✕</span>
        </template>
        <template slot="apply" slot-scope="record">
          <span v-if="record.daily_sample_apply_limit.is_infinite">不限</span>
          <span v-else>{{record.daily_sample_apply_limit.limit}}</span>
        </template>
        <template slot="roll" slot-scope="record">
          <span v-if="record.daily_live_group_apply_limit.is_infinite">不限</span>
          <span v-else>{{record.daily_live_group_apply_limit.limit}}</span>
        </template>
        <template slot="special" slot-scope="record">
          <span v-if="record.daily_special_apply_limit.is_infinite">不限</span>
          <span v-else>{{record.daily_special_apply_limit.limit}}</span>
        </template>
        <template slot="limit" slot-scope="record">
          <span v-if="record.good_price_limit.is_infinite">不限</span>
          <span v-else>{{record.good_price_limit.limit}}</span>
        </template>
      </a-table>
      <div class="edit-mode" v-if="isEditStatus">
        <table class="n-table">
          <thead>
            <tr>
              <th>折一等级</th>
              <th>每日可申请寄样件数</th>
              <th>每日可报名拼播场数</th>
              <th>每日可报名专场数</th>
              <th>单件样品限价（元）</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i,idx) in formList" :key="idx">
              <td>LV{{idx+1}}</td>
              <td>
                <a-checkbox @change="onChangeCheckStatus(idx, 'daily_sample_apply_limit')" :checked="i.daily_sample_apply_limit.is_infinite">不限</a-checkbox>
                <div class="input-split">
                  <a-input-number :min="0" :step="1" :precision="0" v-model="i.daily_sample_apply_limit.limit" :disabled="i.daily_sample_apply_limit.is_infinite" style="width:100px;"></a-input-number>
                </div>
              </td>
              <td>
                <a-checkbox @change="onChangeCheckStatus(idx, 'daily_live_group_apply_limit')" :checked="i.daily_live_group_apply_limit.is_infinite">不限</a-checkbox>
                <div class="input-split">
                  <a-input-number :min="0" :step="1" :precision="0" v-model="i.daily_live_group_apply_limit.limit" :disabled="i.daily_live_group_apply_limit.is_infinite" style="width:100px;"></a-input-number>
                </div>
              </td>
              <td>
                <a-checkbox @change="onChangeCheckStatus(idx, 'daily_special_apply_limit')" :checked="i.daily_special_apply_limit.is_infinite">不限</a-checkbox>
                <div class="input-split">
                  <a-input-number :min="0" :step="1" :precision="0" v-model="i.daily_special_apply_limit.limit" :disabled="i.daily_special_apply_limit.is_infinite" style="width:100px;"></a-input-number>
                </div>
              </td>
              <td>
                <a-checkbox @change="onChangeCheckStatus(idx, 'good_price_limit')" :checked="i.good_price_limit.is_infinite">不限</a-checkbox>
                <div class="input-split">
                  <a-input-number :min="0" :step="1" :precision="0" v-model="i.good_price_limit.limit" :disabled="i.good_price_limit.is_infinite" style="width:100px;"></a-input-number>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="new-person-level">
          新人等级:
          <a-select v-model="new_level_setting" style="width: 120px">
            <a-select-option :value="i.value" v-for="i in userLevelOption" :key="i.value">{{i.label}}</a-select-option>
          </a-select> 及以下
        </div>
        <div>
          <a-button type="primary" @click="handlePostData" class="mr-10" v-auth="'control_panel_modify_welfare_setting'" :loading="isPosting===true">保存</a-button>
          <a-button @click="isEditStatus=false">取消</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const equityColumns = [
  {
    title: "折一等级",
    width: 140,
    scopedSlots: { customRender: "level" }
  },
  {
    title: "新人",
    width: 140,
    scopedSlots: { customRender: "new" }
  },
  {
    title: "每日可申请寄样件数",
    align: "center",
    scopedSlots: { customRender: "apply" },
    width: 140
  },
  {
    title: "每日可报名拼播场数",
    scopedSlots: { customRender: "roll" },
    width: 140
  },
  {
    title: "每日可报名专场数",
    scopedSlots: { customRender: "special" },
    width: 140
  },
  {
    title: "单件样品限价（元）",
    scopedSlots: { customRender: "limit" },
    width: 130
  }
];
import { getKuranWelfare, patchKuranWelfare } from "@/service/operate";
import { checkPer } from "@/utils/index";
import { userLevelOption } from "@/const/index";
export default {
  data() {
    return {
      equityColumns,
      userLevelOption,
      listLoading: false,
      isEditStatus: false,
      isPosting: false,
      list: [],
      formList: [],
      new_level_setting: 1
    };
  },
  created() {
    this.getEquityData();
  },
  methods: {
    // 获取权益列表
    async getEquityData() {
      if (!checkPer("control_panel_welfare_setting")) {
        this.$message.error("无权限获取列表");
        return false;
      }

      this.listLoading = true;
      const { err, res } = await getKuranWelfare();
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const originRes = JSON.parse(
            JSON.stringify(res.data.kuran_welfare_setting)
          );
          this.list = [];
          this.formList = [];
          for (const key in originRes) {
            if (originRes.hasOwnProperty(key)) {
              this.list.push(originRes[key]);
            }
          }
          this.new_level_setting = res.data.new_level_setting;
          this.formList = JSON.parse(JSON.stringify(this.list));
        }
      }
    },
    // 改变是否无限
    onChangeCheckStatus(idx, oKey) {
      this.formList[idx][oKey]["is_infinite"] = !this.formList[idx][oKey][
        "is_infinite"
      ];
      if (this.formList[idx][oKey]["is_infinite"] === true) {
        this.$set(this.formList[idx][oKey], "limit", null);
      }
    },
    // 判断数据是否有必填项 --> true 有未填写的， false 全都填写了
    checkIfDataNotFill(formList) {
      return formList.some(i => {
        return (
          (i.daily_live_group_apply_limit.is_infinite === false &&
            i.daily_live_group_apply_limit.limit === null) ||
          (i.daily_sample_apply_limit.is_infinite === false &&
            i.daily_sample_apply_limit.limit === null) ||
          (i.good_price_limit.is_infinite === false &&
            i.good_price_limit.limit === null) ||
          (i.daily_special_apply_limit.is_infinite === false &&
            i.daily_special_apply_limit.limit === null)
        );
      });
    },
    // 提交数据
    async handlePostData() {
      if (this.checkIfDataNotFill(this.formList)) {
        this.$message.error("还有字段未填写完全，请仔细检查");
        return false;
      }
      this.isPosting = true;
      const _kuran_welfare_setting = this.formatListData(this.formList);
      const data = {
        kuran_welfare_setting: _kuran_welfare_setting,
        new_level_setting: this.new_level_setting
      };
      const { err, res } = await patchKuranWelfare(data);
      this.isPosting = false;
      if (!err) {
        if (res.success) {
          this.$message.success("更新成功！");
          await this.getEquityData();
          this.isEditStatus = false;
        }
      }
    },
    // array数据转objec
    formatListData(arrayData) {
      let o = {};
      arrayData.forEach((i, idx) => {
        delete i.is_new;
        o[idx + 1] = i;
      });
      return o;
    },
    // 编辑权益
    async handleShowEditStatus() {
      await this.getEquityData();
      this.isEditStatus = true;
    }
  }
};
</script>
<style lang="less" scoped>
.n-table {
  width: 100%;
  th,
  td {
    padding: 5px;
    border: 1px solid #e8e8e8;
  }
  th {
    background: #fafafa;
    padding: 8px 5px;
    text-align: center;
  }
}
.input-split {
  padding: 5px 0;
}
.new-person-level {
  padding: 10px 0 20px 0;
}
.option-bar {
  margin-bottom: 10px;
}
.mr-10 {
  margin-right: 10px;
}
</style>
